import Radium from 'radium';


const LabelsPage = () => {
  
  return (
    <>
    <h1>This is the Labels Page with Contacts Table</h1>
    </>
  )
}

export default Radium(LabelsPage);