import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import CommunityHome from '../components/pages/CommunityHome';
import SearchBar from '../components/common/SearchBar';
import ArtistHome from '../components/pages/artists/ArtistHome';
import FansPage from '../components/pages/Fans';
import LabelsPage from '../components/pages/Labels';
import axios from 'axios';
import { getApiUrl } from '../config/siteConfig';
import '../style/community.css'



const CommunityContainer = (props) => {
  const styles = {
    parentContainer: {
        height: "100%", color: "#fff", textAlign: "center"
    },
    breadCrumb: {
      color: '#ffffff'
    },
    searchBarRow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      marginTop: '2rem',
      },
    hover: {
      apple: {
        ':hover': {
          boxShadow: '0px 0px 15px 6px gray',
          background:
            'linear-gradient(304deg, rgba(71,71,71,1) 0%, rgba(0,0,0,1) 43%)',
          cursor: 'pointer',
        },
      },
      tiktok: {
          ':hover': {
              boxShadow: '0px 0px 15px 6px #e5004e',
              background: '#0000',
              cursor: 'pointer',
          },
      },
      fb: {
          ':hover': {
            background: '#0B84EE',
            cursor: 'pointer',
            boxShadow: '0px 0px 15px 6px #0B84EE',
          },
        },
        spotify: {
          ':hover': {
            boxShadow: '0px 0px 15px 6px #1ED760',
            background: '#1ED760',
            cursor: 'pointer',
          },
        }
  }
  };
  const [activeCommunityPage, setActiveCommunityPage] = useState('CommunityHome');
  const [isFiltered, setIsFiltered] = useState(false);
  const [artistData, setArtistData] = useState()

  // Initial artists data for Artist Home
  const getAllArtistsData = () =>{
    axios({
        url: getApiUrl() + '/api/v1/artists',
        method: 'get',
        headers: {
          token: window.localStorage.getItem('token') || ''
        }
      }).then((res) => {
        setArtistData(res.data)
        
      }).catch((err) => {
        console.log('artist api error: ', err);
      })
}

useEffect(()=>{
    getAllArtistsData()
},[])

if(artistData == null){
  return <>Loading..</>
}

  const onClickCommunityPage = (page) => {
    setActiveCommunityPage(page)
    localStorage.setItem('activeCommunityPage', page)
  };
  console.log(activeCommunityPage)
  // Renders the breadcrumbs based on the current state
  const renderBreadcrumbs = () => {
    let breadcrumbTrail = [];
    
    // Add "Community" as the root breadcrumb
    breadcrumbTrail.push({ name: 'Community', page: 'CommunityHome' });

    // Add the active page as a breadcrumb if it's not the home page
    if (activeCommunityPage !== 'CommunityHome') {
      breadcrumbTrail.push({ name: activeCommunityPage });
    }

    return (
      <div style={{...styles.breadCrumb, margin: '2rem 2rem', fontSize: '1rem'}}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbTrail.map((crumb, index) => (
              <React.Fragment key={index}>
                <li
                key={index}
                className={`breadcrumb-item ${crumb.page ? 'clickable' : ''}`}
                onClick={() => crumb.page && onClickCommunityPage(crumb.page)}  // Handle click if a page is set
                style={{ cursor: crumb.page ? 'pointer' : 'default' }}
              >
                {crumb.name}
              </li>
              {index < breadcrumbTrail.length - 1 && (
                <li style = {{marginLeft: '0.5rem', marginRight: '0.5rem'}}>→</li>
              )}
              </React.Fragment>
            ))}
          </ol>
        </nav>
      </div>
     
    );
  };

  let renderActiveCommunityPage = () => {
    switch(activeCommunityPage){
        case 'Fans':
            return <FansPage />
        case 'Artists':
            return <ArtistHome user={props.user} artists={artistData.artists}/>
        case 'Labels':
            return <LabelsPage />
        default:
            return <CommunityHome onClickCommunityPage={ onClickCommunityPage }/>
    }
        
  }

  return(
    <div>
      <div style={{...styles.searchBarRow, display: 'flex', justifyContent: 'center'}}>
        <SearchBar
            data={[{ knktId: 1, artistName: 'Armout Van Buuren' }, { knktId: 2, artistName: 'Armin Van Buuren' }]}
            getResult={(result) => {}}
            isFiltered={(isFiltered) =>
            setIsFiltered(isFiltered)}
        />
      </div>
      {/* Render Breadcrumbs */}
      <div>
        {activeCommunityPage !== 'CommunityHome' && renderBreadcrumbs()}
      </div>

      { renderActiveCommunityPage() }
    </div>
    
     
    );
};

export default CommunityContainer;
