import { useState, useEffect } from 'react';
import { useSelected } from 'react-redux';
import '../../../style/settings.css'
import { Modal, Row, Col, Button, Form, Dropdown } from 'react-bootstrap';
import {
    updateDashboardMenuIndex,
    updateProfile,
    submitChangePassword,
    resetUpdatePassword } from'../../../actions/settings/settingsAction';
import { updateGenre, updateGoal } from '../../../utils/userUtils';
import SignupStepSelectGenre from '../dashboard/SignupStepSelectGenre';
import SignupStepSelectGoal from '../dashboard/SignupStepSelectGoal';

import '../../../style/settings.css';

function Account({props}) {
    if (!props.profile) {
        // Handle the case where userProfile is null or undefined
        return <div>Loading profile or profile not found...</div>;
      }
    useEffect(() => {
    console.log('Props rendered in Account.jsx:', props);
    }, [props]);
      
    // console.log("user profile inside account component", userProfile);
    const [ profile, setProfile ] = useState({});
    const [ showSuccessful, setShowSuccessful ] = useState(false);
    const [ showError, setShowError ] = useState(false);
    const [ showModal,setShowModal ] = useState(false)
    const [ showUpdateGoal, setShowUpdateGoal ] = useState(false);
    const [ showUpdateGenre, setShowUpdateGenre ] = useState(false);
    const [ goalError, setGoalError ] = useState('');
    const [ genreError, setGenreError ] = useState('');
    const [ selectedGoals, setSelectedGoals ] = useState([]);
    const [ selectedGenres, setSelectedGenres ] = useState([]);

    
    
    // const [isLoading, setIsLoading] = useState(true); // Assuming you have a loading state
    // const [error, setError] = useState(null); // Assuming you handle errors

    // // Example: If userProfile is fetched within this component, you would set isLoading and error appropriately.

    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    // if (error) {
    //     return <div>An error occurred: {error.message}</div>;
    // }
    // Deconstruct profile props
    useEffect(()=> {
        if (props.profile != null && props.user.genre) {
            // const { firstName, lastName, profileUrl, account, email, phone, knktId, updateError, updateSuccessful} = props.profile;
            // const myProfile = { firstName, lastName, profileUrl, account, email, phone, knktId, updateError, updateSuccessful};
          
            setProfile({...props.profile,
                        goal: props.user.goal,
                        genre: props.user.genre,
                        updateError: null,
                        updateSuccessful: null
                        });
            // console.log("This is the profile on line 55 of Account", profile)
            setSelectedGoals(profile.goal);
            setSelectedGenres(profile.genre);
            if (showModal){
                if (profile.updateError) {
                    setShowError(true);
                }
                if (updateSuccessful) {
                    setShowSuccessful(true);
    
                }
            }

            
        }    
    }, [props.profile, props.activeTab, showModal]);

    // useEffect(()=> {
    //     if (props.profile.updateSuccessful) {
    //         const { firstName, lastName, profileUrl, account, email, phone, knktId, updateError, updateSuccessful } = profile;
    //         console.log("update successfull in props.profile.updatesSuccesfful useEffect of Account.jsx",updateSuccessful, props.profile);
    //         if (updateError) {
    //             setShowError(true);
    //         }
    //         if (updateSuccessful) {
    //             setShowSuccessful(true);
    //         }
    //     }
    // }, [props.profile.updateSuccessful]);

    const updateMyProfile = _ => {
       props.updateProfile(profile);
       setShowModal(true);
        
    };

    const toggleShowSuccess = _ => {
        setShowSuccessful(false);
        setShowModal(false);
    };

    const toggleShowError = _ => {
        setShowError(false);
        // console.log(showError);
        setShowModal(false);
    };

    const updateForm = event => {
        const p = Object.assign({}, profile);
        //Check if eventKey is passed in from dropdown
        if (event.hasOwnProperty('target')){
            p[event.target.name] = event.target.value;
            setProfile(p);
        } else{
            const { name, value } = event;
            p[name] = value;
        }

    };

    const handleSelect = (eventKey, event) =>{
        updateForm({ name: event.target.name, value: eventKey });
    }

    const location = `${props.user.city || ''} ${props.user.state || ''}`.trim() || "Set your Location";

    // Update Goal Modal Functionality
    const handleShowUpdateGoal = _ => {
        setShowUpdateGoal(true);
    };

    const handleDismissShowUpdateGoal = _ => {
        setShowUpdateGoal(false);
    }

    const selectGoal = ({type}) => {
        // clear genre error message
        setGoalError('');
    
        if (selectedGoals.indexOf(type) > -1) {
          // remove goal
          const goals = [];
          selectedGoals.forEach(g => {
            if (g !== type) {
              goals.push(g);
            }
          });
          setSelectedGoals([...goals]);
        } else {
          // add goal
          setSelectedGoals(prevGoals => [...selectedGoals, type]);
        }
    };

    const validateGoal = _ => {
        return (selectedGoals.length > 0);
    };

    // Submit Goal Function
    const submitGoals = async () => {
        const goalOk = validateGoal(selectedGoals);

        if (goalOk) {
            const updated = await updateGoal(selectedGoals);

            if (updated){
                setShowUpdateGoal(false);
            } else {
                setGoalError('Failed to update goal, please try again!')
            } 
        } else {
            setGoalError('Please select at least one Goal!');
        }
    }

    // Update Genre Modal Functionality
    const handleShowUpdateGenre = _ => {
        setShowUpdateGenre(true);
    };

    const handleDismissShowUpdateGenre = _ => {
        setShowUpdateGenre(false);
    }

    const selectGenre = ({type}) => {
        // clear genre error message
        setGenreError('');
    
        if (selectedGenres.indexOf(type) > -1) {
          // remove goal
          const genres = [];
          selectedGenres.forEach(g => {
            if (g !== type) {
              genres.push(g);
            }
          });
          setSelectedGenres([...genres]);
        } else {
          // add goal
          setSelectedGenres(prevGenres => [...selectedGenres, type]);
        }
    };

    const validateGenre = _ => {
        return (selectedGenres.length > 0);
    };

    // Submit Genre Function
    const submitGenres = async () => {
        const genreOk = validateGenre(selectedGenres);

        if (genreOk) {
            const updated = await updateGenre(selectedGenres);

            if (updated){
                setShowUpdateGenre(false);
            } else {
                setGenreError('Failed to update genre, please try again!')
            } 
        } else {
            setGenreError('Please select at least one Genre!');
        }
    }


    return (
        
        <div className="settings-tab">
            
                <Form>
                    <h3>First Name</h3>
                    <Form.Control 
                    value={profile.firstName}
                    onChange = {updateForm}
                    name="firstName"
                    />
                    <h3>Last Name</h3>
                    <Form.Control 
                    value={profile.lastName}
                    onChange = {updateForm}
                    name="lastName"
                    />
                    <h3>Profile URL</h3>
                    <Form.Control 
                    value={profile.profileUrl}
                    onChange={updateForm}
                    name="profileUrl"
                    />
                    <h3>Account Type</h3>
                    <Form.Control 
                    value={profile.account}
                    onChange={updateForm}
                    name="account"
                    />
                    <h3>Location</h3>
                    <Form.Control 
                    value={location} 
                    onChange={updateForm}
                    name="location"
                    />
                    <h3>Email</h3>
                    <Form.Control 
                    value={profile.email}
                    type="email"
                    disabled={true}
                    readOnly
                    />
                    <h3>Phone</h3>
                    <Form.Control 
                    value={profile.phone}
                    onChange = {updateForm}
                    name="phone"
                    />
                    <h3>KNKT ID</h3>
                    <Form.Control 
                    value={profile.knktId}
                    disabled={true}
                    readOnly
                    />
                    <h3>Genres</h3>
                    <div style={{display: 'flex', justifyContent: 'space-evenly' }}>
                        
                        <Dropdown onSelect={handleSelect}>
                            <Dropdown.Toggle variant="success" id="dropdown-genre">
                                Show Your Genres
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            {(selectedGenres || []).map((genre,index) => (
                                <Dropdown.Item key={index}>
                                    {genre}
                                </Dropdown.Item>
                            ))}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button variant="outline-info" onClick={handleShowUpdateGenre}>
                            Update Genres
                        </Button>
                    </div>
                    <h3>Goals</h3>
                    <div style={{display: 'flex', justifyContent: 'space-evenly'  }}>
                        
                            <Dropdown onSelect={handleSelect}>
                                <Dropdown.Toggle variant="success" id="dropdown-goals">
                                    Show Your Goals
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                {(selectedGoals || []).map((goal,index) => (
                                    <Dropdown.Item key={index}>
                                        {goal}
                                    </Dropdown.Item>
                                ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button variant="outline-info" onClick={handleShowUpdateGoal}>
                                Update Goals
                            </Button>
                    </div>
                    

                </Form>
                
                
            <div className="button-group">
                <Button variant="outline-primary" onClick={updateMyProfile.bind(this)}>
                    Submit
                </Button>
            </div>
           

            { showSuccessful && (
                <Modal show={showSuccessful} onHide={toggleShowSuccess}>
                    <Modal.Header closeButton>
                        <Modal.Title style={{ color: '#00ff00'}}>Awesome!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ color: '#ffffff'}}>Your account has been updated successfully!</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggleShowSuccess}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            { showError && (
              <Modal show={showError} onHide={toggleShowError}>
                  <Modal.Header closeButton>
                      <Modal.Title style={{ color: '#ff0000'}}>Oops!</Modal.Title>
                  </Modal.Header>
                  <Modal.Body style={{ color: '#ffffff'}}>{profile.updateError}</Modal.Body>
                  <Modal.Footer>
                      <Button variant="secondary" onClick={toggleShowError}>
                          Close
                      </Button>
                  </Modal.Footer>
              </Modal>
            )}

            { showUpdateGenre && (
                <Modal
                show={showUpdateGenre} 
                size='xl' 
                onHide={handleDismissShowUpdateGenre}
                backdropClassName={'modal-background'}
                backdrop={'static'}
                centered
            > 
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <SignupStepSelectGenre
                        selectGenre={selectGenre}
                        selectedGenres={selectedGenres}
                        GenreError={genreError}/>
                    </Modal.Body>
                    <Modal.Footer>
                    <Row>
                        <Col xs={12} md={8}>
                            <Button onClick={submitGenres}>
                                Submit Genres
                            </Button>
                        </Col>
                    </Row>
                    </Modal.Footer>
                </Modal>)
            }

            { showUpdateGoal && (
            <Modal
            show={showUpdateGoal} 
            size='xl' 
            onHide={handleDismissShowUpdateGoal}
            backdropClassName={'modal-background'}
            backdrop={'static'}
            centered
          > 
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <SignupStepSelectGoal
                selectGoal={selectGoal}
                selectedGoals={selectedGoals}
                goalError={goalError}/>
            </Modal.Body>
            <Modal.Footer>
              <Row>
                <Col xs={12} md={8}>
                    <Button onClick={submitGoals}>
                        Submit Goals
                    </Button>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>)
            }
       </div>
    )
};

export default Account;